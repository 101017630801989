<template>
  <b-row>

    <b-col cols="12">
      <b-table
          :key="key"
          :current-page="currentPage"
          :fields="fields"
          :filter="filter"
          :filter-included-fields="filterOn"
          :items="items"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          hover
          responsive
      >
        <!-- Name $ Success Icon -->

        <template #cell(monday)="data">
          <div v-if="days && days[0] && days[0].formatted_times">
            <div v-for="times in days[0].formatted_times">
              <b-avatar :variant="data.item.mocolor" class="my-1 mr-1" rounded size="10">
                <feather-icon icon="CheckIcon" size="1"/>
                {{ times.start + ' - ' + times.end }}
              </b-avatar>
              <span>{{ times.start + ' - ' + times.end }}</span>
            </div>
          </div>
        </template>

        <template #cell(tuesday)="data">
          <div v-if=" days && days[1] && days[1].formatted_times">
            <div v-for="times in days[1].formatted_times">
              <b-avatar :variant="data.item.mocolor" class="my-1 mr-1" rounded size="10">
                <feather-icon icon="CheckIcon" size="1"/>
                {{ times.start + ' - ' + times.end }}
              </b-avatar>
              <span>{{ times.start + ' - ' + times.end }}</span>
            </div>
          </div>
        </template>

        <template #cell(wednesday)="data">
          <div v-if="days && days[2] && days[2].formatted_times">
            <div v-for="times in days[2].formatted_times">
              <b-avatar :variant="data.item.mocolor" class="my-1 mr-1" rounded size="10">
                <feather-icon icon="CheckIcon" size="1"/>
                {{ times.start + ' - ' + times.end }}
              </b-avatar>
              <span>{{ times.start + ' - ' + times.end }}</span>
            </div>
          </div>
        </template>

        <template #cell(thursday)="data">
          <div v-if="days && days[3] && days[3].formatted_times">
            <div v-for="times in days[3].formatted_times">
              <b-avatar :variant="data.item.mocolor" class="my-1 mr-1" rounded size="10">
                <feather-icon icon="CheckIcon" size="1"/>
                {{ times.start + ' - ' + times.end }}
              </b-avatar>
              <span>{{ times.start + ' - ' + times.end }}</span>
            </div>
          </div>
        </template>

        <template #cell(friday)="data">
          <div v-if="days && days[4] && days[4].formatted_times">
            <div v-for="times in days[4].formatted_times">
              <b-avatar :variant="data.item.mocolor" class="my-1 mr-1" rounded size="10">
                <feather-icon icon="CheckIcon" size="1"/>
                {{ times.start + ' - ' + times.end }}
              </b-avatar>
              <span>{{ times.start + ' - ' + times.end }}</span>
            </div>
          </div>
        </template>

        <template #cell(saturday)="data">
          <div v-if="days && days[5] && days[5].formatted_times">
            <div v-for="times in days[5].formatted_times">
              <b-avatar :variant="data.item.mocolor" class="my-1 mr-1" rounded size="10">
                <feather-icon icon="CheckIcon" size="1"/>
                {{ times.start + ' - ' + times.end }}
              </b-avatar>
              <span>{{ times.start + ' - ' + times.end }}</span>
            </div>
          </div>
        </template>

        <template #cell(sunday)="data">
          <div v-if="days && days[6] && days[6].formatted_times">
            <div v-for="times in days[6].formatted_times">
              <b-avatar :variant="data.item.mocolor" class="my-1 mr-1" rounded size="10">
                <feather-icon icon="CheckIcon" size="1"/>
                {{ times.start + ' - ' + times.end }}
              </b-avatar>
              <span>{{ times.start + ' - ' + times.end }}</span>
            </div>
          </div>
        </template>
      </b-table>
    </b-col>


  </b-row>
</template>

<script>

import {
  BAvatar,
  BBadge,
  BButton,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import MomentMixin from '@/mixins/MomentMixin'
import {days} from '@/constants/config'
import practice from '@/apis/modules/practice'

export default {

  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem
  },
  directives: {
    Ripple
  },
  mixins: [MomentMixin],
  data () {
    return {
      key: 0,
      utcConvertedUnAvalibility: [],
      days: [],
      perPage: 10,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],

      fields: [
        {
          key: 'monday',
          label: 'Monday'
        },
        {
          key: 'tuesday',
          label: 'Tuesday'
        },
        {
          key: 'wednesday',
          label: 'wednesday'
        },
        {
          key: 'thursday',
          label: 'thursday'
        },
        {
          key: 'friday',
          label: 'friday'
        },
        {
          key: 'saturday',
          label: 'saturday'
        },
        {
          key: 'sunday',
          label: 'sunday'
        }


      ],
      items: [

        {
          'monday': '09-00 - 12.00',
          'mocolor': 'success',
          'tuesday': '09-00 - 12.00',
          'tucolor': 'success',
          'wednesday': '09-00 - 12.00',
          'wecolor': 'success',
          'thursday': '09-00 - 12.00',
          'thcolor': 'success',
          'friday': '09-00 - 12.00',
          'frcolor': 'success',
          'saturday': 'Unavailable',
          'sacolor': 'secondary',
          'sunday': '09-00 - 12.00',
          'sucolor': 'success'
        }
      ]
    }
  },
  methods: {
    async getMyUnAvailabilities () {
      this.addLoader = true
      const Response = (await practice.getSalariedStaff(this.$route.params.id)).data.data

      try {
        this.utcConvertedUnAvalibility = Response.un_availabilities.map((x) => ({
          day: x.day,
          day_number: x.day_number,
          times: x.times.map((m) => ({
            start: this.momentFormat(m.start, 'HH:mm'),
            end: this.momentFormat(m.end, 'HH:mm')
          }))
        }))

        this.days.forEach((day, index) => {
          this.utcConvertedUnAvalibility.forEach((unavailability) => {
            if (day.day_number === unavailability.day_number) {
              let new_times = []
              unavailability.times.forEach((time) => {
                new_times.push(time)
              })
              this.days[index].formatted_times = new_times
            }
          })
        })
        this.key++
        this.addLoader = false
      } catch (error) {
        this.convertAndNotifyError(error)
        this.addLoader = false
      }
    }
  },
  async mounted () {
    this.days = days
    await this.getMyUnAvailabilities()
  },


}
</script>


<style scoped>

</style>
