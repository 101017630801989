<template>
  <div class="mx-1">
    <!-- Working Days -->
    <b-overlay
        :show="addLoader"
        rounded="sm"
    >
      <b-row>
        <b-col cols="12" md="12">
          <b-form-group
              label="Days"
          >
          </b-form-group>
        </b-col>
        <b-col class="mb-1" cols="12" md="12">

          <b-button
              v-for="(day,index) in days"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="btn-icon rounded-circle "
              variant="white"
              :key="index"
              @click="toggleDaySelection(day)"
          >
            <b-avatar
                :class="isWorkingHourAvailableForThisDay(day.day) ? 'text-white bg-primary ' : 'text-dark border-secondary'"
                :style="isWorkingHourAvailableForThisDay(day.day) ? 'backgroundColor:#1B9AAA !important' : ''"
                :text="day.text"/>
          </b-button>
        </b-col>
      </b-row>


      <b-row class="pt-3">
        <b-col v-show="workingHours.length" cols="12" md="12">
          <b-form-group
              label="Working Hours"
          >
          </b-form-group>
        </b-col>

        <b-col class="mb-1" cols="12" md="12">
        </b-col>
      </b-row>
      <validation-observer
          ref="workingHoursForm"
          #default="{ handleSubmit }"
      >
        <b-form
            ref="form"
            class="repeater-form "
        >
          <!-- Add Button -->
          <div v-for="(day,index) in workingHours" :key="index" class="mb-1">
            <b-row v-for="(time,innerIndex) in day.times" :key="innerIndex">
              <b-col v-if="innerIndex === 0" class="mt-1" cols="12 pr-0" md="2">
                <h6>{{ day.day }}</h6>
              </b-col>
              <!-- <b-col v-else class="mt-1" cols="12 pr-0" md="2">
                <h6></h6>
              </b-col> -->

              <b-col class="mb-1" cols="12" md="4">
                <div class="set-mb">
                  <validation-provider
                      #default="{ errors }"
                      :vid="'StartTime'+index+innerIndex"
                      name="Start time"
                      rules="required"
                  >
                    <flat-pickr
                        v-model="workingHours[index].times[innerIndex].start"
                        :config="{ enableTime: true,  noCalendar: true, dateFormat: 'H:i:ss',}"
                        class="form-control"
                        placeholder="Start time"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>

              </b-col>
              <b-col class="mb-1" cols="12" md="4">
                <div class="set-mb">
                  <validation-provider
                      #default="{ errors }"
                      :vid="'EndTime'+index+innerIndex"
                      name="End time"
                      rules="required"
                  >
                    <flat-pickr
                        v-model="workingHours[index].times[innerIndex].end"
                        :config="{ enableTime: true,  noCalendar: true, dateFormat: 'H:i:ss',}"
                        class="form-control"
                        placeholder="End time"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-col>
              <b-col class="mt-1" cols="12 pr-0" md="2">
                  <div class="set-mb">
                      <p>{{hoursCalculate(workingHours[index])}} hrs</p>
                      <!-- <p>{{new DateDiff(new Date(workingHours[index].times[innerIndex].start), new Date(workingHours[index].times[innerIndex].end)).days()}}</p> -->
                      <!-- {{workingHours[index].times[innerIndex].end}} -->
                  </div>
              </b-col>    
            </b-row>
          </div>
        </b-form>
      </validation-observer>

      <!-- Save & Cancle Button -->

      <div class="d-flex">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            @click.prevent="submitForm"
        >
          Save
        </b-button>
        <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class="text-primary ml-1 shadow"
            type="button"
            variant="outline-white"
            @click="$emit('close')"
        >
          Cancel
        </b-button>
      </div>

    </b-overlay>
  </div>
</template>

<script>

import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTimepicker,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BOverlay,
  BPagination,
  BRow,
  BTable
} from 'bootstrap-vue'
import {heightTransition} from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import {days} from '@/constants/config'
import flatPickr from 'vue-flatpickr-component'
import MomentMixin from '@/mixins/MomentMixin'
import SettingsAPI from '@/apis/modules/settings'
import DateDiff from 'date-diff'
import moment from 'moment'
export default {
  components: {
    flatPickr,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    BAvatar,
    BBadge,
    BCard,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    BTable,
    BFormTimepicker,
    BOverlay
  },
  directives: {
    Ripple
  },
  props: {
   workingHours:{
    type: Array,
    required: true
   }
  },
  mixins: [heightTransition, MomentMixin],
  data () {
    return {
      addLoader: false,
      days: null,
      nextTodoId: 1,
      value: '',
      utcTime: [],
      // workingHours:[]
    }
  },
  async mounted () {
    this.days = days
    this.initTrHeight()
  },
  created () {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.initTrHeight)
  },
  computed: {
    isWorkingHourAvailableForThisDay () {
      return (day) => _.some(this.workingHours, {day: day})
    }
  },
  
  methods: {
    async submitForm () {
      this.addLoader = true
      this.$refs.workingHoursForm.validate().then(async success => {
        if (success) {
          try {
            //convert to utc
            this.utcTime = this.workingHours.map((x) => ({
              day_number: this.getWeekDayNumberViaDate(x),
              times: x.times.map((m) => ({
                start: this.getUTCConvertedTime(x.day_number, m.start),
                end: this.getUTCConvertedTime(x.day_number, m.end)
              }))
            }))

            if (this.utcTime.length === 0){
              this.showErrorMessage('Please add at least one new  Working hours')
            }else {
              await SettingsAPI.updateWorkingHours({working_hours: this.utcTime}, this.$route.params.id)
              console.log('utc',this.utcTime);
              this.showSuccessMessage('Working hours updated successfully')
              this.$emit('close')
            }

            this.addLoader = false
          } catch (error) {
            this.convertAndNotifyError(error)
            this.addLoader = false
          }
        }
      })
      this.addLoader = false

    },
    hoursCalculate(workingHours){
      
      if(workingHours.times[0].end && workingHours.times[0].start){
        let start = (workingHours.times[0].start).split(':')
        let end = (workingHours.times[0].end).split(':')
        let startDate = new Date()  
        let endDate = new Date();

        startDate.setHours(start[0], start[1], start[2])
        endDate.setHours(end[0], end[1], end[2])
        
        // var diff = Math.abs(endDate.getTime() - startDate.getTime()) / 3600000;
        let timeDiffHours =  new DateDiff(new Date(endDate), new Date(startDate)).hours()
        // let timeDiffMinutes =  new DateDiff(new Date(endDate), new Date(startDate)).minutes()
        // let decimalDiff = endDate - startDate

        return timeDiffHours;
      }
    },
    toggleDaySelection (selectedDay) {
      const index = this.workingHours.findIndex(obj => obj.day === selectedDay.day)
      if (index >= 0) {
        this.workingHours.splice(index, 1)
      } else {
        this.workingHours.push({
          day_number: selectedDay.day_number,
          day: selectedDay.day,
          times: [{}]
        })
      }
    },
    async removeSlot (index, innerIndex) {
      await this.workingHours[index].times.splice(innerIndex, 1)
      if (this.workingHours[index].times.length === 0) this.workingHours.splice(index, 1)
    },
    addSlot (slotToBeAddTime) {
      slotToBeAddTime.push({})
    },
    removeItem (index) {
      this.workingHours.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight () {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    }
  },
 
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

span.b-avatar {
  background-color: transparent !important;
}

.bg-primary-avatar {
  background-color: #1B9AAA !important;
}
</style>
