<template>
  <div>
    <b-card no-body>
      <b-overlay
          :show="loading"
          rounded="sm"
      >

      </b-overlay>
    </b-card>

  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BRow,
  BFormFile,
  BOverlay
} from 'bootstrap-vue'
import profile from '@/apis/modules/profile'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import Ripple from 'vue-ripple-directive'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import store from '../../../../store/profile/index'
import fileUploader from "@/constants/fileUploader";
import {imageBaseApiUrl} from '@/constants/config'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'profile',
  components: {
    store,
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BFormFile,
    BOverlay,
    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  mixins: [SuccessMessage, ErrorMessages],
  directives: {
    Ripple
  },
  data() {
    return {

    }
  },
  props: {
    // userData: {
    //   type: Object,
    //   required: true
    // }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  watch: {
    userData(val) {
      this.userImage = this.getUserImage()
      this.form.first_name = val.first_name
      this.form.last_name = val.last_name
      this.form.email = val.email
      this.form.phone_number = val.phone_number ? val.phone_number : ''
      this.form.locum_job_role = val.locum ? val.locum.job_role.name : ''
      this.home_address.address = val.home_address && val.home_address[0] ? val.home_address[0].address : ''
      this.home_address.city = val.home_address && val.home_address[0] ? val.home_address[0].city : ''
      this.home_address.postal_code = val.home_address && val.home_address[0] ? val.home_address[0].postal_code : ''
      this.bank_details.name = val.bank_details.name
      this.bank_details.account_number = val.bank_details.account_number
      this.bank_details.sort_code = val.bank_details.sort_code
    }
  },
  methods: {

  },
  mounted() {
    // console.log(this.userData.job_roles)
    this.loginUser()
  }
}
</script>

<style scoped>
.bank-details {
  /*position: absolute;*/
  /*height: 20.19px;*/
  margin-left: 5px;
  left: 2%;
  right: 77.73%;
  /*top: calc(50% - 20.19px/2 + 161.59px);*/
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* Dark */
  color: #171822;
}

.optional-class {
  position: absolute;
  height: 21px;
  left: 437px;
  right: 20px;
  margin-top: 3px;
  margin-bottom: 10px;
  /*top: calc(50% - 21px/2 + 162px);*/
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  text-align: right;
  /* $dark-grey */
  color: #949494;
}
</style>
