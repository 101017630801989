var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-sidebar", {
    attrs: {
      id: "add-new-user-sidebar1",
      disabled: "isEditProfileSidebarActive",
      backdrop: "",
      "bg-variant": "white",
      "no-header": "",
      right: "",
      shadow: "",
      "sidebar-class": "sidebar-lg"
    },
    on: {
      change: function(val) {
        return _vm.$emit("update:is-edit-profile-sidebar-active", val)
      },
      hidden: function($event) {
        _vm.resetForm
        _vm.tabIndex = 1
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hide = ref.hide
          return [
            _c(
              "div",
              {
                staticClass:
                  "\n        d-flex\n        justify-content-between\n        align-items-center\n        content-sidebar-header\n        px-2\n        py-1\n      "
              },
              [
                _c("h4", { staticClass: "mb-0" }, [_vm._v("Edit Profile")]),
                _c("feather-icon", {
                  staticClass: "ml-1 cursor-pointer",
                  attrs: { icon: "XIcon", size: "16" },
                  on: { click: hide }
                })
              ],
              1
            ),
            _c(
              "validation-observer",
              { ref: "refFormObserver" },
              [
                _c(
                  "b-card",
                  [
                    _c(
                      "b-tabs",
                      {
                        attrs: { lazy: "" },
                        model: {
                          value: _vm.tabIndex,
                          callback: function($$v) {
                            _vm.tabIndex = $$v
                          },
                          expression: "tabIndex"
                        }
                      },
                      [
                        _c("b-tab", { attrs: { title: "Personal Info" } }, [
                          _c("div", { staticClass: "alert-body" }, [
                            _c(
                              "div",
                              [
                                _c(
                                  "b-card",
                                  { attrs: { "no-body": "" } },
                                  [
                                    _c(
                                      "b-overlay",
                                      {
                                        attrs: {
                                          show: _vm.personalInfoLoading,
                                          rounded: "sm"
                                        }
                                      },
                                      [
                                        _c(
                                          "b-row",
                                          {},
                                          [
                                            _c(
                                              "b-col",
                                              { attrs: { cols: "2" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-md-flex flex-row mb-2  text-md-left text-center "
                                                  },
                                                  [
                                                    _vm.user
                                                      ? _c("b-avatar", {
                                                          staticClass:
                                                            "mt-2 mr-3 mr-md-0",
                                                          attrs: {
                                                            src: _vm.getUserImage(
                                                              _vm.user.id,
                                                              true
                                                            ),
                                                            "badge-variant":
                                                              "success",
                                                            variant:
                                                              "light-primary",
                                                            size: "70px"
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ]
                                            ),
                                            _c(
                                              "b-col",
                                              { attrs: { cols: "10" } },
                                              [
                                                _vm.changeImageButton
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "mt-3 font-weight-bolder profile-text"
                                                      },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            attrs: {
                                                              variant: "primary"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.changeImage()
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "align-middle"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Change Image"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        null === null
                                                          ? _c(
                                                              "b-button",
                                                              {
                                                                staticClass:
                                                                  "ml-1",
                                                                attrs: {
                                                                  variant:
                                                                    "danger"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.removeImage()
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "align-middle"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Remove Image"
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                !_vm.changeImageButton
                                                  ? _c(
                                                      "div",
                                                      { staticClass: "mt-2" },
                                                      [
                                                        _c("b-form-file", {
                                                          attrs: {
                                                            id: "wildcard",
                                                            accept: "image/*"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.uploadImage,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.uploadImage = $$v
                                                            },
                                                            expression:
                                                              "uploadImage"
                                                          }
                                                        }),
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "float-right ml-1 mt-1",
                                                            attrs: {
                                                              variant: ""
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                _vm.changeImageButton = true
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "align-middle"
                                                              },
                                                              [_vm._v("Cancel")]
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "float-right mt-1",
                                                            attrs: {
                                                              disabled:
                                                                _vm.uploadImage
                                                                  .length === 0,
                                                              variant: "primary"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.uploadFile()
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "align-middle"
                                                              },
                                                              [_vm._v("Upload")]
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            ),
                                            _c(
                                              "validation-observer",
                                              { ref: "userEditValidateForm" },
                                              [
                                                _c(
                                                  "b-form",
                                                  {
                                                    staticClass:
                                                      "auth-login-form mt-2",
                                                    on: {
                                                      submit: function($event) {
                                                        $event.preventDefault()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          xl: "12"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "b-row",
                                                          [
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "6"
                                                                }
                                                              },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    " First Name"
                                                                  )
                                                                ]),
                                                                _c(
                                                                  "validation-provider",
                                                                  {
                                                                    attrs: {
                                                                      name:
                                                                        "First Name",
                                                                      rules:
                                                                        "required"
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "default",
                                                                          fn: function(
                                                                            ref
                                                                          ) {
                                                                            var errors =
                                                                              ref.errors
                                                                            return [
                                                                              _c(
                                                                                "b-form-input",
                                                                                {
                                                                                  staticClass:
                                                                                    "mb-1",
                                                                                  attrs: {
                                                                                    id:
                                                                                      "h-first-name",
                                                                                    placeholder:
                                                                                      "First Name"
                                                                                  },
                                                                                  model: {
                                                                                    value:
                                                                                      _vm
                                                                                        .form
                                                                                        .first_name,
                                                                                    callback: function(
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.form,
                                                                                        "first_name",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                    expression:
                                                                                      "form.first_name"
                                                                                  }
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "small",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-danger"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      errors[0]
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "6"
                                                                }
                                                              },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    " Second Name"
                                                                  )
                                                                ]),
                                                                _c(
                                                                  "validation-provider",
                                                                  {
                                                                    attrs: {
                                                                      name:
                                                                        "NI Number",
                                                                      rules:
                                                                        "numeric"
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "default",
                                                                          fn: function(
                                                                            ref
                                                                          ) {
                                                                            var errors =
                                                                              ref.errors
                                                                            return [
                                                                              _c(
                                                                                "b-form-input",
                                                                                {
                                                                                  staticClass:
                                                                                    "mb-1",
                                                                                  attrs: {
                                                                                    id:
                                                                                      "h-last-name",
                                                                                    placeholder:
                                                                                      "Last Name"
                                                                                  },
                                                                                  model: {
                                                                                    value:
                                                                                      _vm
                                                                                        .form
                                                                                        .last_name,
                                                                                    callback: function(
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        _vm.form,
                                                                                        "last_name",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                    expression:
                                                                                      "form.last_name"
                                                                                  }
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "small",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-danger"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      errors[0]
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "6"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-group",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "Email Address",
                                                                      "label-for":
                                                                        "h-email"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "validation-provider",
                                                                      {
                                                                        attrs: {
                                                                          name:
                                                                            "Email",
                                                                          rules:
                                                                            "required"
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "default",
                                                                              fn: function(
                                                                                ref
                                                                              ) {
                                                                                var errors =
                                                                                  ref.errors
                                                                                return [
                                                                                  _c(
                                                                                    "b-input-group",
                                                                                    {
                                                                                      staticClass:
                                                                                        "input-group-merge"
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b-input-group-prepend",
                                                                                        {
                                                                                          attrs: {
                                                                                            "is-text":
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "feather-icon",
                                                                                            {
                                                                                              attrs: {
                                                                                                icon:
                                                                                                  "MailIcon"
                                                                                              }
                                                                                            }
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "b-form-input",
                                                                                        {
                                                                                          attrs: {
                                                                                            id:
                                                                                              "icons-mail",
                                                                                            placeholder:
                                                                                              "example@.com"
                                                                                          },
                                                                                          model: {
                                                                                            value:
                                                                                              _vm
                                                                                                .form
                                                                                                .email,
                                                                                            callback: function(
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.form,
                                                                                                "email",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                            expression:
                                                                                              "form.email"
                                                                                          }
                                                                                        }
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "small",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-danger"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          errors[0]
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              }
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "6"
                                                                }
                                                              },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    "Date of Birth"
                                                                  )
                                                                ]),
                                                                _c(
                                                                  "validation-provider",
                                                                  {
                                                                    attrs: {
                                                                      name:
                                                                        "Date",
                                                                      rules: ""
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "default",
                                                                          fn: function(
                                                                            ref
                                                                          ) {
                                                                            var errors =
                                                                              ref.errors
                                                                            return [
                                                                              _c(
                                                                                "b-form-group",
                                                                                [
                                                                                  _c(
                                                                                    "flat-pickr",
                                                                                    {
                                                                                      staticClass:
                                                                                        "form-control",
                                                                                      attrs: {
                                                                                        config: {
                                                                                          enableTime: false,
                                                                                          noCalendar: false,
                                                                                          dateFormat:
                                                                                            "Y-m-d"
                                                                                        },
                                                                                        placeholder:
                                                                                          "Select a date"
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          _vm
                                                                                            .form
                                                                                            .dob,
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.form,
                                                                                            "dob",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "form.dob"
                                                                                      }
                                                                                    }
                                                                                  )
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "small",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-danger"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      errors[0]
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          }
                                                                        }
                                                                      ],
                                                                      null,
                                                                      true
                                                                    )
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                  md: "12"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-group",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "NI Number",
                                                                      "label-for":
                                                                        "h-Ni_number"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "validation-provider",
                                                                      {
                                                                        attrs: {
                                                                          name:
                                                                            "NI Number",
                                                                          rules:
                                                                            ""
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "default",
                                                                              fn: function(
                                                                                ref
                                                                              ) {
                                                                                var errors =
                                                                                  ref.errors
                                                                                return [
                                                                                  _c(
                                                                                    "b-input-group",
                                                                                    {
                                                                                      staticClass:
                                                                                        "input-group-merge"
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b-form-input",
                                                                                        {
                                                                                          attrs: {
                                                                                            id:
                                                                                              "icons-Ni_number",
                                                                                            placeholder:
                                                                                              "Enter NI Number"
                                                                                          },
                                                                                          model: {
                                                                                            value:
                                                                                              _vm
                                                                                                .form
                                                                                                .ni_number,
                                                                                            callback: function(
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.form,
                                                                                                "ni_number",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                            expression:
                                                                                              "form.ni_number"
                                                                                          }
                                                                                        }
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "small",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-danger"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          errors[0]
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              }
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                  md: "6"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-group",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "Gender",
                                                                      "label-for":
                                                                        "h-Gender"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "validation-provider",
                                                                      {
                                                                        attrs: {
                                                                          name:
                                                                            "Gender",
                                                                          rules:
                                                                            ""
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "default",
                                                                              fn: function(
                                                                                ref
                                                                              ) {
                                                                                var errors =
                                                                                  ref.errors
                                                                                return [
                                                                                  _c(
                                                                                    "b-input-group",
                                                                                    {
                                                                                      staticClass:
                                                                                        "input-group-merge"
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-select",
                                                                                        {
                                                                                          staticClass:
                                                                                            "w-100",
                                                                                          attrs: {
                                                                                            reduce: function(
                                                                                              title
                                                                                            ) {
                                                                                              return title.value
                                                                                            },
                                                                                            options: [
                                                                                              {
                                                                                                title:
                                                                                                  "Male",
                                                                                                value:
                                                                                                  "Male"
                                                                                              },
                                                                                              {
                                                                                                title:
                                                                                                  "Female",
                                                                                                value:
                                                                                                  "Female"
                                                                                              },
                                                                                              {
                                                                                                title:
                                                                                                  "Non-binary",
                                                                                                value:
                                                                                                  "Non-binary"
                                                                                              }
                                                                                            ],
                                                                                            label:
                                                                                              "title",
                                                                                            placeholder:
                                                                                              "Select Gender"
                                                                                          },
                                                                                          model: {
                                                                                            value:
                                                                                              _vm
                                                                                                .form
                                                                                                .gender,
                                                                                            callback: function(
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.form,
                                                                                                "gender",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                            expression:
                                                                                              "form.gender"
                                                                                          }
                                                                                        }
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "small",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-danger"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          errors[0]
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              }
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  cols: "6",
                                                                  md: "6"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-group",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "Preferred Pronouns",
                                                                      "label-for":
                                                                        "h-Preferred_pronouns"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "validation-provider",
                                                                      {
                                                                        attrs: {
                                                                          name:
                                                                            "Preferred Pronouns",
                                                                          rules:
                                                                            ""
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "default",
                                                                              fn: function(
                                                                                ref
                                                                              ) {
                                                                                var errors =
                                                                                  ref.errors
                                                                                return [
                                                                                  _c(
                                                                                    "b-input-group",
                                                                                    {
                                                                                      staticClass:
                                                                                        "input-group-merge"
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-select",
                                                                                        {
                                                                                          staticClass:
                                                                                            "w-100",
                                                                                          attrs: {
                                                                                            reduce: function(
                                                                                              title
                                                                                            ) {
                                                                                              return title.value
                                                                                            },
                                                                                            options: [
                                                                                              {
                                                                                                title:
                                                                                                  "They/Them",
                                                                                                value:
                                                                                                  "They/Them"
                                                                                              },
                                                                                              {
                                                                                                title:
                                                                                                  "He/Him",
                                                                                                value:
                                                                                                  "He/Him"
                                                                                              },
                                                                                              {
                                                                                                title:
                                                                                                  "She/Her",
                                                                                                value:
                                                                                                  "She/Her"
                                                                                              },
                                                                                              {
                                                                                                title:
                                                                                                  "Prefer not to say",
                                                                                                value:
                                                                                                  "Prefer not to say"
                                                                                              }
                                                                                            ],
                                                                                            label:
                                                                                              "title",
                                                                                            placeholder:
                                                                                              "Select Preferred Pronouns"
                                                                                          },
                                                                                          model: {
                                                                                            value:
                                                                                              _vm
                                                                                                .form
                                                                                                .preferred_pronouns,
                                                                                            callback: function(
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.form,
                                                                                                "preferred_pronouns",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                            expression:
                                                                                              "form.preferred_pronouns"
                                                                                          }
                                                                                        }
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "small",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-danger"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          errors[0]
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              }
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "12"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-group",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "Alias",
                                                                      "label-for":
                                                                        "h-Alias"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "validation-provider",
                                                                      {
                                                                        attrs: {
                                                                          name:
                                                                            "NI Number",
                                                                          rules:
                                                                            "required|numeric"
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "default",
                                                                              fn: function(
                                                                                ref
                                                                              ) {
                                                                                var errors =
                                                                                  ref.errors
                                                                                return [
                                                                                  _c(
                                                                                    "b-input-group",
                                                                                    {
                                                                                      staticClass:
                                                                                        "input-group-merge"
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "b-form-input",
                                                                                        {
                                                                                          attrs: {
                                                                                            id:
                                                                                              "icons-Alias",
                                                                                            placeholder:
                                                                                              "Enter Alias"
                                                                                          },
                                                                                          model: {
                                                                                            value:
                                                                                              _vm
                                                                                                .form
                                                                                                .alias,
                                                                                            callback: function(
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.form,
                                                                                                "alias",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                            expression:
                                                                                              "form.alias"
                                                                                          }
                                                                                        }
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "small",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-danger"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          errors[0]
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              }
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _vm.form
                                                              .locum_job_role
                                                              ? _c(
                                                                  "b-col",
                                                                  {
                                                                    attrs: {
                                                                      cols:
                                                                        "12",
                                                                      md: "12"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-group",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "Locum Job Role",
                                                                          "label-for":
                                                                            "locum_job_role"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b-form-input",
                                                                          {
                                                                            attrs: {
                                                                              readonly:
                                                                                ""
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm
                                                                                  .form
                                                                                  .locum_job_role,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  _vm.form,
                                                                                  "locum_job_role",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "form.locum_job_role"
                                                                            }
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12"
                                                                }
                                                              },
                                                              [
                                                                _c("b-row", [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "bank-details"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Bank Details"
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "optional-class"
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Optional"
                                                                      )
                                                                    ]
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "12"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mt-1"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-form-group",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "Bank Name",
                                                                          "label-for":
                                                                            "v-bank-name"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "validation-provider",
                                                                          {
                                                                            attrs: {
                                                                              name:
                                                                                "Bank Name",
                                                                              rules:
                                                                                _vm
                                                                                  .bank_details
                                                                                  .sort_code ||
                                                                                _vm
                                                                                  .bank_details
                                                                                  .account_number
                                                                                  ? "required"
                                                                                  : ""
                                                                            },
                                                                            scopedSlots: _vm._u(
                                                                              [
                                                                                {
                                                                                  key:
                                                                                    "default",
                                                                                  fn: function(
                                                                                    ref
                                                                                  ) {
                                                                                    var errors =
                                                                                      ref.errors
                                                                                    return [
                                                                                      _c(
                                                                                        "b-form-input",
                                                                                        {
                                                                                          attrs: {
                                                                                            id:
                                                                                              "v-bank-name",
                                                                                            placeholder:
                                                                                              "e.g.Bank of England"
                                                                                          },
                                                                                          model: {
                                                                                            value:
                                                                                              _vm
                                                                                                .bank_details
                                                                                                .name,
                                                                                            callback: function(
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.bank_details,
                                                                                                "name",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                            expression:
                                                                                              "bank_details.name"
                                                                                          }
                                                                                        }
                                                                                      ),
                                                                                      _c(
                                                                                        "small",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-danger"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              errors[0]
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  }
                                                                                }
                                                                              ],
                                                                              null,
                                                                              true
                                                                            )
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  md: "6"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {},
                                                                  [
                                                                    _c(
                                                                      "b-form-group",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "Account Number",
                                                                          "label-for":
                                                                            "account-number"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "validation-provider",
                                                                          {
                                                                            attrs: {
                                                                              name:
                                                                                "Account Number",
                                                                              rules:
                                                                                _vm
                                                                                  .bank_details
                                                                                  .name ||
                                                                                _vm
                                                                                  .bank_details
                                                                                  .sort_code
                                                                                  ? "required|numeric|min_bank_account|max_bank_account"
                                                                                  : "numeric|min_bank_account|max_bank_account"
                                                                            },
                                                                            scopedSlots: _vm._u(
                                                                              [
                                                                                {
                                                                                  key:
                                                                                    "default",
                                                                                  fn: function(
                                                                                    ref
                                                                                  ) {
                                                                                    var errors =
                                                                                      ref.errors
                                                                                    return [
                                                                                      _c(
                                                                                        "b-form-input",
                                                                                        {
                                                                                          attrs: {
                                                                                            id:
                                                                                              "account-number",
                                                                                            placeholder:
                                                                                              "xxxxxxxx"
                                                                                          },
                                                                                          model: {
                                                                                            value:
                                                                                              _vm
                                                                                                .bank_details
                                                                                                .account_number,
                                                                                            callback: function(
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                _vm.bank_details,
                                                                                                "account_number",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                            expression:
                                                                                              "bank_details.account_number"
                                                                                          }
                                                                                        }
                                                                                      ),
                                                                                      _c(
                                                                                        "small",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-danger"
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              errors[0]
                                                                                            )
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ]
                                                                                  }
                                                                                }
                                                                              ],
                                                                              null,
                                                                              true
                                                                            )
                                                                          }
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                attrs: {
                                                                  md: "6"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-group",
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "Sort Code",
                                                                      "label-for":
                                                                        "sort-code"
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "validation-provider",
                                                                      {
                                                                        attrs: {
                                                                          name:
                                                                            "Sort Code",
                                                                          rules:
                                                                            _vm
                                                                              .bank_details
                                                                              .name ||
                                                                            _vm
                                                                              .bank_details
                                                                              .account_number
                                                                              ? "required|numeric|max_sort_code|min_sort_code"
                                                                              : "numeric|max_sort_code|min_sort_code"
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "default",
                                                                              fn: function(
                                                                                ref
                                                                              ) {
                                                                                var errors =
                                                                                  ref.errors
                                                                                return [
                                                                                  _c(
                                                                                    "b-form-input",
                                                                                    {
                                                                                      attrs: {
                                                                                        id:
                                                                                          "sort-code",
                                                                                        placeholder:
                                                                                          "xx-xx-xx"
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          _vm
                                                                                            .bank_details
                                                                                            .sort_code,
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            _vm.bank_details,
                                                                                            "sort_code",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "bank_details.sort_code"
                                                                                      }
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "small",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-danger"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          errors[0]
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              }
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        )
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "mt-2",
                                                                attrs: {
                                                                  md: "12"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "ripple",
                                                                        rawName:
                                                                          "v-ripple.400",
                                                                        value:
                                                                          "rgba(255, 255, 255, 0.15)",
                                                                        expression:
                                                                          "'rgba(255, 255, 255, 0.15)'",
                                                                        modifiers: {
                                                                          "400": true
                                                                        }
                                                                      }
                                                                    ],
                                                                    staticClass:
                                                                      "mr-1",
                                                                    attrs: {
                                                                      type:
                                                                        "submit",
                                                                      variant:
                                                                        "primary"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.updateUserInfo()
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Save "
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-button",
                                                                  {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "ripple",
                                                                        rawName:
                                                                          "v-ripple.400",
                                                                        value:
                                                                          "rgba(186, 191, 199, 0.15)",
                                                                        expression:
                                                                          "'rgba(186, 191, 199, 0.15)'",
                                                                        modifiers: {
                                                                          "400": true
                                                                        }
                                                                      }
                                                                    ],
                                                                    attrs: {
                                                                      type:
                                                                        "reset",
                                                                      variant:
                                                                        "outline-secondary"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        _vm.isEditProfileSidebarActive = false
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "text-secondary"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Cancel "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ]),
                        _c("b-tab", { attrs: { title: "Professional Info" } }, [
                          _c(
                            "div",
                            { staticClass: "alert-body" },
                            [
                              _c("ProfessionalInfo", {
                                attrs: {
                                  UserProfessionalInformation:
                                    _vm.professionalInfo,
                                  User: _vm.User,
                                  PersonalInfo: _vm.user
                                },
                                on: {
                                  close: function($event) {
                                    return _vm.$emit(
                                      "update:is-edit-profile-sidebar-active",
                                      false
                                    )
                                  },
                                  updateProfessionalInfo:
                                    _vm.updateQualifications
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("b-tab", { attrs: { title: "Contact Info" } }, [
                          _c(
                            "div",
                            { staticClass: "alert-body" },
                            [
                              _c("ContactInfo", {
                                attrs: {
                                  professionalInfo: _vm.professionalInformation
                                },
                                on: {
                                  close: function($event) {
                                    return _vm.$emit(
                                      "update:is-edit-profile-sidebar-active",
                                      false
                                    )
                                  },
                                  updateProfessionalInfo:
                                    _vm.updateQualifications,
                                  updateContactInfo:
                                    _vm.updateContactInformation
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("b-tab", { attrs: { title: "Employment Info" } }, [
                          _c(
                            "div",
                            { staticClass: "alert-body" },
                            [
                              _c("EmploymentInfo", {
                                attrs: {
                                  professionalInfo:
                                    _vm.professionalInfo.qalifications
                                },
                                on: {
                                  close: function($event) {
                                    return _vm.$emit(
                                      "update:is-edit-profile-sidebar-active",
                                      false
                                    )
                                  },
                                  updateProfessionalInfo:
                                    _vm.updateQualifications,
                                  updateEmploymentInformation:
                                    _vm.updateContactInformation
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("b-tab", { attrs: { title: "Unavailability" } }, [
                          _c(
                            "div",
                            { staticClass: "alert-body" },
                            [
                              _c("UnAvailability", {
                                attrs: {
                                  unavailabilities: _vm.user
                                    ? _vm.utcConvertedUnAvalibility
                                    : []
                                },
                                on: {
                                  close: function($event) {
                                    return _vm.$emit(
                                      "update:is-edit-profile-sidebar-active",
                                      false
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("b-tab", { attrs: { title: "Working hours" } }, [
                          _c(
                            "div",
                            { staticClass: "alert-body" },
                            [
                              _c("WorkingHours", {
                                attrs: {
                                  workingHours: _vm.user
                                    ? _vm.utcConvertedWorkingHours
                                    : []
                                },
                                on: {
                                  close: function($event) {
                                    return _vm.$emit(
                                      "update:is-edit-profile-sidebar-active",
                                      false
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }