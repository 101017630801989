<template>
  <div>
    <b-card no-body>
      <b-overlay
          :show="loading"
          rounded="sm"
      >
        <b-row class="">


          <!-- first name -->

          <b-col class="" cols="12" xl="12">
            <validation-observer ref="EditPersonalInfoForm">
              <b-form @submit.prevent>
                <div class="mr-2 ml-2">
                <b-row>
                  <b-col cols="12" md="12">
                    <b-form-group
                        label="Date hired"
                        label-for="Date hired"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="Date hired"
                          rules=""
                      >
                        <b-input-group class="input-group-merge">
                          <flat-pickr
                              v-model="form.date_hired"
                              :config="{ enableTime: false, noCalendar: false, dateFormat: 'd-m-Y'}"
                              class="form-control"
                              placeholder="Select hired date"


                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Employment type -->
                  <b-col cols="12" md="12">
                    <b-form-group
                        label="Employment type"
                        label-for="h-Preferred_pronouns"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="Employment type"
                          rules="required"
                      >
                        <b-input-group class="input-group-merge">

                          <v-select
                              class="w-100"
                              v-model="form.employment_type"
                              :reduce="title => title.value"
                              :options="[{title:'Full time', value:'Full time'},
                                         {title:'Part time', value:'Part time'},
                                         {title:'Casual', value:'Casual'},
                                         {title:'AARS', value:'AARS'}
                                         ]"

                              label="title"
                              placeholder="Employment type"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
<!--                  Salary-->

<!--                 {{ numberWithCommas(form.salary)}}-->
                  <b-col cols="12" md="12">
                    <div class="mt-1">
                      <b-form-group
                          class="input-group-merge"
                          label="Salary"
                          label-for="home-Salary"
                      >

                        <validation-provider
                            #default="{ errors }"
                            name="Salary"
                            rules=""
                        >
                          <b-input-group class="input-group-merge">

                          <b-input-group-prepend is-text>
                            £
                          </b-input-group-prepend>
                          <b-form-input
                              id="home-address"
                              v-model="form.salary"
                              placeholder="Enter Salary"
                              type="number"


                          />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col cols="6" md="6">
                    <b-form-group
                        label="DBS status"
                        label-for="h-DBS status"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="DBS status"
                          rules="required"
                      >
                        <b-input-group class="input-group-merge">

                          <v-select
                              class="w-100"
                              v-model="form.dbs_status"
                              :reduce="title => title.value"
                              :options="[{title:'Not started', value:'Not started'},
                                         {title:'In progress', value:'In progress'},
                                         {title:'Completed', value:'Completed'},
                                         ]"
                              label="title"
                              placeholder="Select DBS status"


                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6" md="6">
                    <b-form-group
                        label="DBS renewal Date "
                        label-for="h-DBS renewal Date"
                    >
                      <validation-provider
                          #default="{ errors }"
                          name="DBS renewal Date"
                          rules="required"
                      >
                        <b-input-group class="input-group-merge">
                          <flat-pickr
                              v-model="form.dbs_renewal_date"
                              :config="{ enableTime: false, noCalendar: false, dateFormat: 'd-m-Y'}"
                              class="form-control"
                              placeholder="Select DBS renewal Date "

                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>


                  <!-- submit and reset -->
                  <b-col md="12" class="mt-2">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="mr-1"
                        type="submit"
                        variant="primary"
                        @click="updateEmploymentInfo()"
                    >
                      <!--                      @click="profileUpdate()"-->
                      Save
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        type="reset"
                        variant="outline-secondary"
                        @click="cancel()"
                    >
        <span class="text-secondary">
          Cancel
        </span>
                    </b-button>
                  </b-col>
                </b-row>
                </div>
              </b-form>
            </validation-observer>

          </b-col>
        </b-row>
      </b-overlay>
    </b-card>

  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BRow,
  BFormFile,
  BOverlay
} from 'bootstrap-vue'
import profile from '@/apis/modules/profile'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import Ripple from 'vue-ripple-directive'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import store from '../../../../store/profile/index'
import fileUploader from "@/constants/fileUploader";
import {imageBaseApiUrl} from '@/constants/config'
import {mapActions, mapGetters} from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import staffBank from '@/apis/modules/staffBank'
import moment from 'moment'
import MomentMixin from '@/mixins/MomentMixin'



export default {
  name: 'profile',
  components: {
    flatPickr,
    store,
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroup,
    BFormFile,
    BOverlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  mixins: [MomentMixin,SuccessMessage, ErrorMessages],
  directives: {
    Ripple
  },
  data() {
    return {
      loading: false,
      isHaveProfileImage: false,
      userImage: null,
      changeImageButton: true,
      uploadImage: [],
      userData: {},

      form: {
        date_hired:'',
        employment_type:'',
        salary:'',
        dbs_status:'',
        dbs_renewal_date:''
      },
      EmploymentInfo: {}

    }
  },
  props: {
    // userData: {
    //   type: Object,
    //   required: true
    // }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  watch: {
    EmploymentInfo(val) {
      this.form.date_hired = val?this.momentFormat(val.date_hired,'DD MM YYYY'):''
      this.form.dbs_renewal_date = val?this.momentFormat(val.dbs_renewal_date,'DD MM YYYY'):''
      this.form.dbs_status = val?val.dbs_status:''
      this.form.salary = val?val.salary:''
      this.form.employment_type = val?val.employment_type:''
    }
  },
  methods: {
    async updateEmploymentInfo() {
      try {
        const payload = {
          date_hired: moment(this.form.date_hired,'DD.MM.YYYY').utc().format('YYYY-MM-DD'),
          employment_type: this.form.employment_type,
          salary: this.form.salary,
          dbs_status: this.form.dbs_status,
          dbs_renewal_date: moment(this.form.dbs_renewal_date,'DD.MM.YYYY').utc().format('YYYY-MM-DD')
        }
        const response = await staffBank.updateEmploymentInformation(payload, this.$route.params.id)
        if(response.data.success) {
          this.showSuccessMessage('Employment Info updated successfully')
          this.$emit('updateEmploymentInformation')
        }

      } catch (error) {
        this.convertAndNotifyError(error)
      }

    },

    async getUserEmployInfo() {
      const response = await staffBank.getUserEmployInformation(this.$route.params.id)
      this.EmploymentInfo = response.data[0]
    },

    cancel() {
    this.$emit('close')
    },
    async loginUser() {
      try {
        this.loading = true
        this.userData = (await profile.getCurrentUser()).data.data
        this.loading = false
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },

    // numberWithCommas(x) {
    //  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
    // }

  },
  mounted() {
    // console.log(this.userData.job_roles)
    this.loginUser()
    this.getUserEmployInfo()
  }
}
</script>

<style scoped>

.bank-details {
  /*position: absolute;*/
  /*height: 20.19px;*/
  margin-left: 5px;
  left: 2%;
  right: 77.73%;
  /*top: calc(50% - 20.19px/2 + 161.59px);*/
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* Dark */
  color: #171822;
}

.optional-class {
  position: absolute;
  height: 21px;
  left: 437px;
  right: 20px;
  margin-top: 3px;
  margin-bottom: 10px;
  /*top: calc(50% - 21px/2 + 162px);*/
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  text-align: right;
  /* $dark-grey */
  color: #949494;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
