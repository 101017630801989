var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "heading-elements" }, [
    _c("ul", { staticClass: "list-inline mb-0" }, [
      _vm.availableActions.includes("collapse")
        ? _c("li", [
            _c(
              "a",
              {
                class: { rotate: _vm.isCollapsed },
                attrs: { "data-action": "collapse" },
                on: {
                  click: function($event) {
                    return _vm.$emit("collapse")
                  }
                }
              },
              [
                _c("feather-icon", {
                  attrs: { icon: "ChevronDownIcon", size: "16" }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.availableActions.includes("refresh")
        ? _c("li", [
            _c(
              "a",
              {
                attrs: { "data-action": "reload" },
                on: {
                  click: function($event) {
                    return _vm.$emit("refresh")
                  }
                }
              },
              [
                _c("feather-icon", {
                  attrs: { icon: "RotateCwIcon", size: "14" }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.availableActions.includes("close")
        ? _c("li", [
            _c(
              "a",
              {
                attrs: { "data-action": "close" },
                on: {
                  click: function($event) {
                    return _vm.$emit("close")
                  }
                }
              },
              [_c("feather-icon", { attrs: { icon: "XIcon", size: "14" } })],
              1
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }