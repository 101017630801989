<template>
  <b-sidebar
    id="add-new-user-sidebar1"
    disabled="isEditProfileSidebarActive"
    backdrop
    bg-variant="white"
    no-header
    right
    shadow
    sidebar-class="sidebar-lg"
    @change="(val) => $emit('update:is-edit-profile-sidebar-active', val)"
    @hidden="
      resetForm;
      tabIndex = 1;
    "
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h4 class="mb-0">Edit Profile</h4>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer ref="refFormObserver">
        <!-- Form -->

        <b-card>
          <b-tabs v-model="tabIndex" lazy>
            <b-tab title="Personal Info">
              <div class="alert-body">
                <div>
                  <b-card no-body>
                    <b-overlay :show="personalInfoLoading" rounded="sm">
                      <b-row class="">
                        <b-col cols="2">

                          <div class="d-md-flex flex-row mb-2  text-md-left text-center ">
                            <b-avatar v-if="user" :src="getUserImage(user.id, true)"
                                      class="mt-2 mr-3 mr-md-0"
                                      badge-variant="success"
                                      variant="light-primary"
                                      size="70px"
                            />
                            <!--                            <b-avatar v-if="user" :src="this.getUserImage(user.id)"-->
                            <!--                                      class="mt-2 mr-3 mr-md-0"-->
                            <!--                                      size="70px"-->
                            <!--                            />-->
                          </div>
                        </b-col>
                        <b-col cols="10">
                          <div
                            v-if="changeImageButton"
                            class="mt-3 font-weight-bolder profile-text"
                          >
                            <b-button
                              class=""
                              variant="primary"
                              @click="changeImage()"
                            >
                              <span class="align-middle">Change Image</span>
                            </b-button>
                            <b-button
                              v-if="null === null"
                              class="ml-1"
                              variant="danger"
                              @click="removeImage()"
                            >
                              <span class="align-middle">Remove Image</span>
                            </b-button>

                            <!--                              <b-row class="pl-0 ">-->
                            <!--                                  <b-col class="mt-1 font-weight-light" cols="12">-->
                            <!--                                    <span class="text-dark ">Maximum upload size 1MB</span>-->
                            <!--                                  </b-col>-->

                            <!--                              </b-row>-->
                          </div>
                          <div v-if="!changeImageButton" class="mt-2">
                            <b-form-file
                              id="wildcard"
                              v-model="uploadImage"
                              accept="image/*"
                            />

                            <b-button
                              class="float-right ml-1 mt-1"
                              variant=""
                              @click="changeImageButton = true"
                            >
                              <span class="align-middle">Cancel</span>
                            </b-button>
                            <b-button
                              :disabled="uploadImage.length === 0"
                              class="float-right mt-1"
                              variant="primary"
                              @click="uploadFile()"
                            >
                              <span class="align-middle">Upload</span>
                            </b-button>
                            <!--                            <b-button class="float-right mt-1" variant="primary" @click="uploadFile()" :disabled="uploadImage.length===0">-->
                            <!--                              <span class="align-middle" >upload</span>-->
                            <!--                            </b-button>-->
                          </div>
                        </b-col>
                        <validation-observer ref="userEditValidateForm">
                          <b-form class="auth-login-form mt-2" @submit.prevent>
                            <b-col class="" cols="12" xl="12">
                              <b-row>
                                <b-col cols="12" md="6">
                                  <label> First Name</label>
                                  <validation-provider
                                    #default="{ errors }"
                                    name="First Name"
                                    rules="required"
                                  >
                                    <b-form-input
                                      id="h-first-name"
                                      v-model="form.first_name"
                                      class="mb-1"
                                      placeholder="First Name"
                                    />
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-col>

                                <b-col cols="12" md="6">
                                  <label> Second Name</label>
                                  <validation-provider
                                    #default="{ errors }"
                                    name="NI Number"
                                    rules="numeric"
                                >
                                  <!-- <b-input-group class="input-group-merge"> -->

                                    <b-form-input
                                      id="h-last-name"
                                      v-model="form.last_name"
                                      class="mb-1"
                                      placeholder="Last Name"
                                    />
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-col>

                                <b-col cols="12" md="6">
                                  <b-form-group
                                    label="Email Address"
                                    label-for="h-email"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="Email"
                                      rules="required"
                                    >
                                      <b-input-group class="input-group-merge">
                                        <b-input-group-prepend is-text>
                                          <feather-icon icon="MailIcon" />
                                        </b-input-group-prepend>
                                        <b-form-input
                                          id="icons-mail"
                                          v-model="form.email"
                                          placeholder="example@.com"
                                        />
                                      </b-input-group>
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>

                                <b-col cols="12" md="6">
                                  <label>Date of Birth</label>

                                  <validation-provider
                                    #default="{ errors }"
                                    name="Date"
                                    rules=""
                                  >
                                    <b-form-group>
                                      <flat-pickr
                                        v-model="form.dob"
                                        :config="{
                                          enableTime: false,
                                          noCalendar: false,
                                          dateFormat: 'Y-m-d',
                                        }"
                                        class="form-control"
                                        placeholder="Select a date"
                                      />
                                    </b-form-group>
                                    <small class="text-danger">{{
                                      errors[0]
                                    }}</small>
                                  </validation-provider>
                                </b-col>

                                <b-col cols="6" md="12">
                                  <b-form-group
                                    label="NI Number"
                                    label-for="h-Ni_number"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="NI Number"
                                      rules=""
                                    >
                                      <b-input-group class="input-group-merge">
                                        <b-form-input
                                          id="icons-Ni_number"
                                          v-model="form.ni_number"
                                          placeholder="Enter NI Number"
                                        />
                                      </b-input-group>
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>

                                <!--                            <b-col cols="12" md="12">-->
                                <!--                              <label> Role</label>-->
                                <!--                              <b-form-group class="mb-1">-->
                                <!--                                <validation-provider-->
                                <!--                                    #default="{ errors }"-->
                                <!--                                    name="Role"-->
                                <!--                                    rules="required"-->
                                <!--                                >-->
                                <!--                                <v-select-->
                                <!--                                    v-model="selectedJobRole"-->
                                <!--                                    :options="jobRoleOption"-->
                                <!--                                    label="name"-->
                                <!--                                    placeholder="Select Role"-->
                                <!--                                />-->
                                <!--                                  <small class="text-danger">{{ errors[0] }}</small>-->
                                <!--                                </validation-provider>-->
                                <!--                              </b-form-group>-->
                                <!--                            </b-col>-->

                                <!--                            <b-col cols="12" md="12">-->
                                <!--                              <label> Telephone</label>-->
                                <!--                              <validation-provider-->
                                <!--                                  #default="{ errors }"-->
                                <!--                                  name="Phone Number"-->
                                <!--                                  rules="tel|min_tel|max_tel"-->
                                <!--                              >-->
                                <!--                                <b-form-input id="h-phone_number"-->
                                <!--                                              v-model="form.phone_number"-->
                                <!--                                              class="mb-1"-->
                                <!--                                              placeholder="Telephone"-->
                                <!--                                />-->
                                <!--                                <small class="text-danger">{{ errors[0] }}</small>-->
                                <!--                              </validation-provider>-->
                                <!--                            </b-col>-->

                                <!--                            <b-col cols="12" md="12" class="mb-3">-->
                                <!--                              <label> Email</label>-->
                                <!--                              <validation-provider-->
                                <!--                                  #default="{ errors }"-->
                                <!--                                  name="Email"-->
                                <!--                                  rules="required"-->
                                <!--                              >-->
                                <!--                                <b-form-input id="h-email"-->
                                <!--                                              v-model="form.email"-->
                                <!--                                              placeholder="Email"-->
                                <!--                                />-->
                                <!--                                <small class="text-danger">{{ errors[0] }}</small>-->
                                <!--                              </validation-provider>-->
                                <!--                            </b-col>-->

                                <!--            Gender-->

                                <b-col cols="6" md="6">
                                  <b-form-group
                                    label="Gender"
                                    label-for="h-Gender"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="Gender"
                                      rules=""
                                    >
                                      <b-input-group class="input-group-merge">
                                        <v-select
                                          class="w-100"
                                          v-model="form.gender"
                                          :reduce="(title) => title.value"
                                          :options="[
                                            { title: 'Male', value: 'Male' },
                                            {
                                              title: 'Female',
                                              value: 'Female',
                                            },
                                            {
                                              title: 'Non-binary',
                                              value: 'Non-binary',
                                            },
                                          ]"
                                          label="title"
                                          placeholder="Select Gender"
                                        />
                                      </b-input-group>
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <!--            Preferred Pronouns-->

                                <b-col cols="6" md="6">
                                  <b-form-group
                                    label="Preferred Pronouns"
                                    label-for="h-Preferred_pronouns"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="Preferred Pronouns"
                                      rules=""
                                    >
                                      <b-input-group class="input-group-merge">
                                        <v-select
                                          class="w-100"
                                          v-model="form.preferred_pronouns"
                                          :reduce="(title) => title.value"
                                          :options="[
                                            {
                                              title: 'They/Them',
                                              value: 'They/Them',
                                            },
                                            {
                                              title: 'He/Him',
                                              value: 'He/Him',
                                            },
                                            {
                                              title: 'She/Her',
                                              value: 'She/Her',
                                            },
                                            {
                                              title: 'Prefer not to say',
                                              value: 'Prefer not to say',
                                            },
                                          ]"
                                          label="title"
                                          placeholder="Select Preferred Pronouns"
                                        />
                                      </b-input-group>
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>

                                <b-col cols="12" md="12">
                                  <b-form-group
                                    label="Alias"
                                    label-for="h-Alias"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="NI Number"
                                      :rules="'required|numeric'"
                                    >
                                      <b-input-group class="input-group-merge">
                                        <b-form-input
                                          id="icons-Alias"
                                          v-model="form.alias"
                                          placeholder="Enter Alias"
                                        />
                                      </b-input-group>
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>

                                <b-col
                                  v-if="form.locum_job_role"
                                  cols="12"
                                  md="12"
                                >
                                  <b-form-group
                                    label="Locum Job Role"
                                    label-for="locum_job_role"
                                  >
                                    <b-form-input
                                      v-model="form.locum_job_role"
                                      readonly
                                    />
                                  </b-form-group>
                                </b-col>

                                <b-col cols="12">
                                  <b-row>
                                    <div class="bank-details">Bank Details</div>

                                    <div class="optional-class">Optional</div>
                                  </b-row>
                                </b-col>

                                <b-col cols="12" md="12">
                                  <div class="mt-1">
                                    <b-form-group
                                      label="Bank Name"
                                      label-for="v-bank-name"
                                    >
                                      <validation-provider
                                        #default="{ errors }"
                                        name="Bank Name"
                                        :rules="
                                          bank_details.sort_code ||
                                          bank_details.account_number
                                            ? 'required'
                                            : ''
                                        "
                                      >
                                        <b-form-input
                                          id="v-bank-name"
                                          v-model="bank_details.name"
                                          placeholder="e.g.Bank of England"
                                        />
                                        <small class="text-danger">{{
                                          errors[0]
                                        }}</small>
                                      </validation-provider>
                                    </b-form-group>
                                  </div>
                                </b-col>

                                <b-col md="6">
                                  <div class="">
                                    <b-form-group
                                      label="Account Number"
                                      label-for="account-number"
                                    >
                                      <validation-provider
                                        #default="{ errors }"
                                        name="Account Number"
                                        :rules="
                                          bank_details.name ||
                                          bank_details.sort_code
                                            ? 'required|numeric|min_bank_account|max_bank_account'
                                            : 'numeric|min_bank_account|max_bank_account'
                                        "
                                      >
                                        <b-form-input
                                          id="account-number"
                                          v-model="bank_details.account_number"
                                          placeholder="xxxxxxxx"
                                        />
                                        <small class="text-danger">{{
                                          errors[0]
                                        }}</small>
                                      </validation-provider>
                                    </b-form-group>
                                  </div>
                                </b-col>
                                <b-col md="6">
                                  <b-form-group
                                    label="Sort Code"
                                    label-for="sort-code"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="Sort Code"
                                      :rules="
                                        bank_details.name ||
                                        bank_details.account_number
                                          ? 'required|numeric|max_sort_code|min_sort_code'
                                          : 'numeric|max_sort_code|min_sort_code'
                                      "
                                    >
                                      <b-form-input
                                        id="sort-code"
                                        v-model="bank_details.sort_code"
                                        placeholder="xx-xx-xx"
                                      />
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>

                                <!--                            <b-col cols="12" md="12">-->
                                <!--                              <label>Telephone</label>-->
                                <!--                              <b-input-group class="input-group-merge mb-1">-->
                                <!--                                <b-input-group-prepend is-text>-->
                                <!--                                  <feather-icon icon="PhoneIcon"/>-->
                                <!--                                </b-input-group-prepend>-->
                                <!--                                <validation-provider-->
                                <!--                                    #default="{ errors }"-->
                                <!--                                    name="Phone Number"-->
                                <!--                                    rules="required"-->
                                <!--                                >-->
                                <!--                                <b-form-input v-model="form.phone_number" placeholder="Telephone"/>-->
                                <!--                                  <small class="text-danger">{{ errors[0] }}</small>-->
                                <!--                                </validation-provider>-->
                                <!--                              </b-input-group>-->

                                <!--                            </b-col>-->
                                <!--                            <b-col cols="12" md="12">-->
                                <!--                              <label> Email</label>-->
                                <!--                              <b-input-group class="input-group-merge mb-1">-->
                                <!--&lt;!&ndash;                                <b-input-group-prepend is-text>&ndash;&gt;-->
                                <!--&lt;!&ndash;                                  <feather-icon icon="MailIcon"/>&ndash;&gt;-->

                                <!--                                  <validation-provider-->
                                <!--                                      #default="{ errors }"-->
                                <!--                                      name="Email"-->
                                <!--                                      rules="required"-->
                                <!--                                  >-->
                                <!--                                <b-form-input v-model="form.email" disabled placeholder="Email"/>-->
                                <!--                                    <small class="text-danger">{{ errors[0] }}</small>-->
                                <!--                                  </validation-provider>-->
                                <!--&lt;!&ndash;                                </b-input-group-prepend>&ndash;&gt;-->
                                <!--                              </b-input-group>-->
                                <!--                            </b-col>-->
                                <!--                      <b-col cols="12" md="12">-->
                                <!--                        <label>NI Number</label>-->
                                <!--                        <b-input-group class="input-group-merge mb-3">-->
                                <!--                          <b-input-group-prepend is-text>-->
                                <!--                            <feather-icon icon="PocketIcon" />-->
                                <!--                          </b-input-group-prepend>-->
                                <!--                          <b-form-input placeholder="NI Number" />-->
                                <!--                        </b-input-group>-->
                                <!--                      </b-col>-->

                                <!-- submit and reset -->
                                <b-col md="12" class="mt-2">
                                  <b-button
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    class="mr-1"
                                    type="submit"
                                    variant="primary"
                                    @click="updateUserInfo()"
                                  >
                                    Save
                                  </b-button>
                                  <b-button
                                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                    type="reset"
                                    variant="outline-secondary"
                                    @click="isEditProfileSidebarActive = false"
                                  >
                                    <span class="text-secondary"> Cancel </span>
                                  </b-button>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-form>
                        </validation-observer>
                      </b-row>
                    </b-overlay>
                  </b-card>
                </div>
              </div>
            </b-tab>

            <b-tab title="Professional Info">
              <div class="alert-body">
                <ProfessionalInfo @close="$emit('update:is-edit-profile-sidebar-active',false)"
                                  :UserProfessionalInformation="professionalInfo"
                         :User="User" :PersonalInfo="user" @updateProfessionalInfo="updateQualifications"/>
              </div>
            </b-tab>
            <!--              contact info-->
            <b-tab title="Contact Info">
              <div class="alert-body">
                <ContactInfo @close="$emit('update:is-edit-profile-sidebar-active',false)" :professionalInfo="professionalInformation"
                                  @updateProfessionalInfo="updateQualifications"
                                  @updateContactInfo="updateContactInformation"
                />
              </div>
            </b-tab>
            <!--              Employee info-->
            <b-tab title="Employment Info">
              <div class="alert-body">
                <EmploymentInfo @close="$emit('update:is-edit-profile-sidebar-active',false)" :professionalInfo="professionalInfo.qalifications"
                                  @updateProfessionalInfo="updateQualifications"
                                  @updateEmploymentInformation="updateContactInformation"
                />
              </div>
            </b-tab>
            <!--            Notes-->
            <!--            <b-tab title="Notes">-->
            <!--              <div class="alert-body">-->
            <!--                <UserNotes @close="$emit('update:is-edit-profile-sidebar-active',false)" :professionalInfo="professionalInfo"-->
            <!--                                  @updateProfessionalInfo="updateQualifications"/>-->
            <!--              </div>-->

            <!--            </b-tab>-->
            <b-tab title="Unavailability">
              <div class="alert-body">
                <UnAvailability
                  @close="$emit('update:is-edit-profile-sidebar-active', false)"
                  :unavailabilities="user ? utcConvertedUnAvalibility : []"
                />
              </div>
            </b-tab>
            <b-tab title="Working hours">
              <div class="alert-body">
                <WorkingHours
                  @close="$emit('update:is-edit-profile-sidebar-active', false)" 
                  :workingHours="user ? utcConvertedWorkingHours : []"/>
              </div>
            </b-tab>
          </b-tabs>
        </b-card>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BLink,
  BOverlay,
  BRow,
  BSidebar,
  BTab,
  BTabs,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import BCardActions from "@/@core/components/b-card-actions/BCardActions.vue";
import practice from "@/apis/modules/practice";
import PersonalInfo from "./view/personalinfo";
import ProfessionalInfo from "./view/professionalinfo";
import WorkingHours from "./view/working-hours.vue";
import myUpload from "vue-image-crop-upload";
import settings from "@/apis/modules/settings";
import staffBank from "@/apis/modules/staffBank";
import fileUploader from "@/constants/fileUploader";
import { baseApiUrl } from "@/constants/config";
import MomentMixin from "@/mixins/MomentMixin";
import ContactInfo from "./view/contact-info";
import EmploymentInfo from "./view/empoyment-info";
import UserNotes from "./view/user-notes";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";

export default {
  mixins: [MomentMixin],

  components: {
    ContactInfo,
    EmploymentInfo,
    UserNotes,
    UnAvailability: () => import("./view/unavailability"),
    "my-upload": myUpload,
    PersonalInfo,
    ProfessionalInfo,
    WorkingHours,
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    VuePerfectScrollbar,
    draggable,
    BTab,
    BTabs,
    BRow,
    BCol,
    BAvatar,
    BLink,
    BBadge,
    BInputGroupPrepend,
    BCard,
    BOverlay,
    BFormFile,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BCardActions,
  },

  directives: {
    BCardActions,
    Ripple,
  },
  data() {
    return {
      tabIndex: 0,
      professionalInformation:{},
      professionalInfo: {},
      professionalInformationItSystems: {},
      changeImageButton: true,
      uploadImage: [],
      jobRoleOption: [],
      personalInfoLoading: false,
      selectedJobRole: {},
      show: false,
      removeButton: true,
      user: null,
      form: {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        role: "",
        locum_job_role: "",
        gender: "",
        alias: "",
        preferred_pronouns: "",
        ni_number: "",
        dob: "",
      },
      bank_details: {
        name: "",
        account_number: "",
        sort_code: "",
      },
      home_address: {
        address: "",
        city: "",
        postal_code: "",
      },
      utcConvertedUnAvalibility: [],
      utcConvertedWorkingHours: [],
    };
  },
  model: {
    prop: "isEditProfileSidebarActive",
    event: "update:is-edit-profile-sidebar-active",
  },
  props: {
    isEditProfileSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: false,
    },
    planOptions: {
      type: Array,
      required: false,
    },
    User: {},
  },
  watch: {
    async isEditProfileSidebarActive(val) {
      if (val) {
        await this.getUserDetails();
        await this.jobRoleList();
      }
    },
  },

  methods: {
    resetForm() {},
    toggle() {
      this.$emit("toggleSideBars");
    },
    toggleShow() {
      this.show = !this.show;
      setTimeout(() => {
        document
          .getElementsByClassName("vicp-drop-area")
          .item(0)
          .querySelectorAll('input[type="file"]')[0]
          .setAttribute("accept", "image/*");
      }, 100);
    },
    async personalInfoUpdate() {
      try {
        // await profile.profileUpdate(this.form)
        this.showSuccessMessage("Profile updated");
        this.$emit("savePersonalInfo");
      } catch (e) {
        this.convertAndNotifyError(e);
      }
    },
    async getUserDetails() {
      try {
        this.personalInfoLoading = true
        console.log('this.$route.params.id',this.$route.params.id)
        const Response = await (practice.getSalariedStaff(this.$route.params.id))
        this.user = Response.data.data
        console.log('this.user',this.user)
        this.utcConvertedUnAvalibility = this.user.un_availabilities.map((x) => ({
          day: x.day,
          day_number: x.day_number,
          times: x.times.map((m) => ({
            start: this.momentFormat(m.start, 'HH:mm:ss'),
            end: this.momentFormat(m.end, 'HH:mm:ss')
          }))
        }))
        this.utcConvertedWorkingHours = this.user.working_hours.map((x) => ({
          day: x.day,
          day_number: x.day_number,
          times: x.times.map((m) => ({
            start: this.momentFormat(m.start, 'HH:mm:ss'),
            end: this.momentFormat(m.end, 'HH:mm:ss')
          }))
        }))

        this.professionalInformation = this.user.qalifications || {}
        this.professionalInfo = this.user
        // this.professionalInformationItSystems =this.user.multiple_it_systems.length === 0 || this.user.multiple_it_systems[0].it_system.length===0?'':this.user.multiple_it_systems.map((x) => ({
        //   value: x.it_system[0].id,
        //   label: x.it_system[0].name,
        // }))
        this.form.first_name = this.user.first_name
        this.form.last_name = this.user.last_name
        this.form.dob = this.user.dob
        this.form.phone_number = this.user.phone_number
        this.form.email = this.user.email
        this.form.alias = this.user.alias
        this.form.ni_number = this.user.ni_number
        this.form.preferred_pronouns = this.user.preferred_pronouns
        this.form.gender = this.user.gender
        this.bank_details.name = this.user.bank_details?this.user.bank_details.name:''
        this.bank_details.account_number = this.user.bank_details?this.user.bank_details.account_number:''
        this.bank_details.sort_code = this.user.bank_details?this.user.bank_details.sort_code:''
        this.form.locum_job_role = val.locum ? val.locum.job_role.name : ''
        this.selectedJobRole = {
          name: this.user.job_roles[0].name,
          id: this.user.job_roles[0].id,
        };

        // this.utcConvertedUnAvalibility = this.user.un_availabilities.map((x) => ({
        //   day: x.day,
        //   day_number: x.day_number,
        //   times: x.times.map((m) => ({
        //     start: this.momentFormat(m.start, 'HH:mm:ss'),
        //     end: this.momentFormat(m.end, 'HH:mm:ss')
        //   }))
        // }))

        this.personalInfoLoading = false

      } catch (error) {
        this.convertAndNotifyError(error);
        this.personalInfoLoading = false;
      }
    },
    async jobRoleList() {
      try {
        const practiceListResponse = await settings.getJobRoleList();
        this.jobRoleOption = practiceListResponse.data.data;
      } catch (e) {
        this.convertAndNotifyError(e);
      }
    },
    async updateUserInfo() {
      if (await this.$refs.userEditValidateForm.validate()) {
        // try {
        //   if (!this.selectedJobRole) {
        //     this.showErrorMessage('please select user Job Role')
        //     return
        //   }
        //   this.personalInfoLoading = true
        //   this.form.job_role_id = this.selectedJobRole.id
        //   let data = (await staffBank.updateSalariedStaffPersonalInfo(this.$route.params.id, this.form)).data.data
        //   this.showSuccessMessage('User profile update successfully')
        //   this.$emit('updatedDetails', data)
        //   this.$emit('updatedRole', this.selectedJobRole.name)
        //   this.personalInfoLoading = false
        //   this.$emit('close')
        //
        // } catch (error) {
        //   this.convertAndNotifyError(error)
        //   this.personalInfoLoading = false
        // }
        try {
          const payload = {
            first_name: this.form.first_name,
            last_name: this.form.last_name,
            email: this.form.email,
            phone_number: this.form.phone_number,
            gender: this.form.gender,
            alias: this.form.alias,
            preferred_pronouns: this.form.preferred_pronouns,
            ni_number: this.form.ni_number,
            dob: this.form.dob
              ? moment(this.form.dob).utc().format("YYYY-MM-DD")
              : "",
          };
          if (this.bank_details.name) {
            payload.bank_details = this.bank_details;
          }
          // if (this.home_address.address) {
          //   payload.home_address = this.home_address
          // }
          // await profile.profileUpdate(payload)
          const response = await staffBank.updateSalariedStaffPersonalInfo(
            this.$route.params.id,
            payload
          );
          this.showSuccessMessage("Profile updated");
          this.$emit("updatedDetails");
          this.$emit("toggleSideBars");
          // await this.$store.dispatch('autoLogin')
        } catch (e) {
          this.convertAndNotifyError(e);
        }
      }
    },

    changeImage() {
      this.changeImageButton = !this.changeImageButton;
    },
    async removeImage() {
      this.$swal({
        title: "Are you sure you want to remove profile image?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, remove",
        cancelButtonText: "No, go back",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          await staffBank.profileRemove(this.$route.params.id)
          this.showSuccessMessage('Profile picture removed successfully!')

          // this.$swal({
          //   icon: 'success',
          //   title: 'Published!',
          //   customClass: {
          //     confirmButton: 'btn btn-success'
          //   }
          // })
        }
      });
    },
    async uploadFile() {
      try {
        fileUploader
          .store(this.uploadImage, {
            progress: (progress) => {
              this.uploadProgress = Math.round(progress * 100);
            },
            visibility: "public-read",
            // baseURL: 'http://hyre-be-v2.test/api/mp',
            baseURL: `${baseApiUrl}`,
          })
          .then(async (response) => {
            const key = response.key;
            await this.setImageKeyToApi(key);
          });
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },
    async setImageKeyToApi(key) {
      await staffBank.profileUpload(this.$route.params.id, {
        key: key,
        name: this.uploadImage.name,
      });

      // this.items[_.findIndex(this.items, {id:this.item_id})].item_image_key = key
      this.showSuccessMessage('Profile picture updated successfully!')
      this.changeImageButton = true


      // setTimeout (() => {
      //   const new_src = `${this.api_url}/items/${this.item_id}/image?${Date.now()}`
      //   var image = document.getElementById(`${this.item_id}_img`);
      //   image.src = new_src
      //   this.item_id = null
      //   this.popupActiveImage = false
      // },500)
    },

    updateQualifications(updatedQualification) {
      this.user.qualifications = updatedQualification;
      this.$emit("refreshProfessionalInfo", this.user.qualifications);
    },

    updateContactInformation() {
      this.$emit("updatedDetails");
      this.$emit("toggleSideBars");
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;
}

.bank-details {
  /*position: absolute;*/
  /*height: 20.19px;*/
  margin-left: 15px;
  left: 2%;
  right: 77.73%;
  /*top: calc(50% - 20.19px/2 + 161.59px);*/
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* Dark */
  color: #171822;
}

.optional-class {
  position: absolute;
  height: 21px;
  left: 437px;
  right: 20px;
  margin-top: 3px;
  margin-bottom: 10px;
  /*top: calc(50% - 21px/2 + 162px);*/
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */
  text-align: right;
  /* $dark-grey */
  color: #949494;
}
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
