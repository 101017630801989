var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.loading, rounded: "sm" } },
            [
              _c(
                "b-row",
                {},
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", xl: "12" } },
                    [
                      _c(
                        "validation-observer",
                        { ref: "EditPersonalInfoForm" },
                        [
                          _c(
                            "b-form",
                            {
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "mr-2 ml-2" },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Date hired",
                                                "label-for": "Date hired"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "Date hired",
                                                  rules: ""
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var errors = ref.errors
                                                      return [
                                                        _c(
                                                          "b-input-group",
                                                          {
                                                            staticClass:
                                                              "input-group-merge"
                                                          },
                                                          [
                                                            _c("flat-pickr", {
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                config: {
                                                                  enableTime: false,
                                                                  noCalendar: false,
                                                                  dateFormat:
                                                                    "d-m-Y"
                                                                },
                                                                placeholder:
                                                                  "Select hired date"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.form
                                                                    .date_hired,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.form,
                                                                    "date_hired",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "form.date_hired"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "small",
                                                          {
                                                            staticClass:
                                                              "text-danger"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ])
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Employment type",
                                                "label-for":
                                                  "h-Preferred_pronouns"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "Employment type",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var errors = ref.errors
                                                      return [
                                                        _c(
                                                          "b-input-group",
                                                          {
                                                            staticClass:
                                                              "input-group-merge"
                                                          },
                                                          [
                                                            _c("v-select", {
                                                              staticClass:
                                                                "w-100",
                                                              attrs: {
                                                                reduce: function(
                                                                  title
                                                                ) {
                                                                  return title.value
                                                                },
                                                                options: [
                                                                  {
                                                                    title:
                                                                      "Full time",
                                                                    value:
                                                                      "Full time"
                                                                  },
                                                                  {
                                                                    title:
                                                                      "Part time",
                                                                    value:
                                                                      "Part time"
                                                                  },
                                                                  {
                                                                    title:
                                                                      "Casual",
                                                                    value:
                                                                      "Casual"
                                                                  },
                                                                  {
                                                                    title:
                                                                      "AARS",
                                                                    value:
                                                                      "AARS"
                                                                  }
                                                                ],
                                                                label: "title",
                                                                placeholder:
                                                                  "Employment type"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.form
                                                                    .employment_type,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.form,
                                                                    "employment_type",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "form.employment_type"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "small",
                                                          {
                                                            staticClass:
                                                              "text-danger"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ])
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "mt-1" },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  staticClass:
                                                    "input-group-merge",
                                                  attrs: {
                                                    label: "Salary",
                                                    "label-for": "home-Salary"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "Salary",
                                                      rules: ""
                                                    },
                                                    scopedSlots: _vm._u([
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var errors =
                                                            ref.errors
                                                          return [
                                                            _c(
                                                              "b-input-group",
                                                              {
                                                                staticClass:
                                                                  "input-group-merge"
                                                              },
                                                              [
                                                                _c(
                                                                  "b-input-group-prepend",
                                                                  {
                                                                    attrs: {
                                                                      "is-text":
                                                                        ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " £ "
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      id:
                                                                        "home-address",
                                                                      placeholder:
                                                                        "Enter Salary",
                                                                      type:
                                                                        "number"
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.form
                                                                          .salary,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.form,
                                                                          "salary",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "form.salary"
                                                                    }
                                                                  }
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "text-danger"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0]
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ])
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6", md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "DBS status",
                                                "label-for": "h-DBS status"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "DBS status",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var errors = ref.errors
                                                      return [
                                                        _c(
                                                          "b-input-group",
                                                          {
                                                            staticClass:
                                                              "input-group-merge"
                                                          },
                                                          [
                                                            _c("v-select", {
                                                              staticClass:
                                                                "w-100",
                                                              attrs: {
                                                                reduce: function(
                                                                  title
                                                                ) {
                                                                  return title.value
                                                                },
                                                                options: [
                                                                  {
                                                                    title:
                                                                      "Not started",
                                                                    value:
                                                                      "Not started"
                                                                  },
                                                                  {
                                                                    title:
                                                                      "In progress",
                                                                    value:
                                                                      "In progress"
                                                                  },
                                                                  {
                                                                    title:
                                                                      "Completed",
                                                                    value:
                                                                      "Completed"
                                                                  }
                                                                ],
                                                                label: "title",
                                                                placeholder:
                                                                  "Select DBS status"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.form
                                                                    .dbs_status,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.form,
                                                                    "dbs_status",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "form.dbs_status"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "small",
                                                          {
                                                            staticClass:
                                                              "text-danger"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ])
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "6", md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "DBS renewal Date ",
                                                "label-for":
                                                  "h-DBS renewal Date"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "DBS renewal Date",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var errors = ref.errors
                                                      return [
                                                        _c(
                                                          "b-input-group",
                                                          {
                                                            staticClass:
                                                              "input-group-merge"
                                                          },
                                                          [
                                                            _c("flat-pickr", {
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                config: {
                                                                  enableTime: false,
                                                                  noCalendar: false,
                                                                  dateFormat:
                                                                    "d-m-Y"
                                                                },
                                                                placeholder:
                                                                  "Select DBS renewal Date "
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.form
                                                                    .dbs_renewal_date,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.form,
                                                                    "dbs_renewal_date",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "form.dbs_renewal_date"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "small",
                                                          {
                                                            staticClass:
                                                              "text-danger"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ])
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "mt-2",
                                          attrs: { md: "12" }
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "ripple",
                                                  rawName: "v-ripple.400",
                                                  value:
                                                    "rgba(255, 255, 255, 0.15)",
                                                  expression:
                                                    "'rgba(255, 255, 255, 0.15)'",
                                                  modifiers: { "400": true }
                                                }
                                              ],
                                              staticClass: "mr-1",
                                              attrs: {
                                                type: "submit",
                                                variant: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.updateEmploymentInfo()
                                                }
                                              }
                                            },
                                            [_vm._v(" Save ")]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "ripple",
                                                  rawName: "v-ripple.400",
                                                  value:
                                                    "rgba(186, 191, 199, 0.15)",
                                                  expression:
                                                    "'rgba(186, 191, 199, 0.15)'",
                                                  modifiers: { "400": true }
                                                }
                                              ],
                                              attrs: {
                                                type: "reset",
                                                variant: "outline-secondary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.cancel()
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "text-secondary"
                                                },
                                                [_vm._v(" Cancel ")]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }